<template>
    <div class="d-flex align-items-center justify-content-between py-10 container" >
        <div class="col-md-12 p-0">
            <div class="card card-custom">
                <div class="card-header">
                    <div class="card-title">
                        <span class="card-icon">
                            <i class="fas fa-shield-alt text-aqua"></i>
                        </span>
                        <h1 class="card-label text-uppercase">PRIVACY POLICY</h1>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 py-5 px-7">
                          <h3>INTRODUCTION</h3>
                          <p>{{ getCompanyInfo.name }} recognizes the importance of privacy of its users and also of maintaining confidentiality of the information provided by its users as a responsible data controller and data processer.</p>
                          <p>This Privacy Policy provides for the practices for handling and securing user's Personal Information (defined hereunder) by {{ getCompanyInfo.name }} and its subsidiaries and affiliates.</p>
                          <p>This Privacy Policy is applicable to any person (&lsquo;User&rsquo;) who purchase, intend to purchase, or inquire about any product(s) or service(s) made available by {{ getCompanyInfo.name }}
                            through any of {{ getCompanyInfo.name }}&rsquo;s customer interface channels including its website, mobile site, mobile app &amp; offline channels including call centers and offices (collectively referred herein as&nbsp;<strong>"Sales Channels"</strong>).</p>
                          <p>For the purpose of this Privacy Policy, wherever the context so requires "you" or "your" shall mean User and the term "we", "us", "our" shall mean {{ getCompanyInfo.name }}. For the purpose of this Privacy Policy, Website means the website(s), mobile site(s) and mobile app(s).</p>
                          <p>By using or accessing the Website or other Sales Channels, the User hereby agrees with the terms of this Privacy Policy and the contents herein. If you disagree with this Privacy Policy please do not use or access our Website or other Sales Channels.</p>
                          <p>This Privacy Policy does not apply to any website(s), mobile sites and mobile apps of third parties, even if their websites/products are linked to our Website.
                            User should take note that information and privacy practices of {{ getCompanyInfo.name }}&rsquo;s business partners, advertisers, sponsors or other sites to which {{ getCompanyInfo.name }} provides hyperlink(s), may be materially different from this Privacy Policy.
                            Accordingly, it is recommended that you review the privacy statements and policies of any such third parties with whom they interact.</p>
                          <p>This Privacy Policy is an integral part of your User Agreement with {{ getCompanyInfo.name }} and all capitalized terms used, but not otherwise defined herein, shall have the respective meanings as ascribed to them in the User Agreement.</p>

                          <h3>USERS OUTSIDE THE GEOGRAPHICAL LIMITS OF Bangladesh</h3>
                          <p>Please note that the data shared with {{ getCompanyInfo.name }} shall be primarily processed in Bangladesh and such other jurisdictions where a third party engaged by {{ getCompanyInfo.name }} may process the data on {{ getCompanyInfo.name }}&rsquo;s behalf.
                            By agreeing to this policy, you are providing {{ getCompanyInfo.name }} with your explicit consent to process your personal information for the purpose(s) defined in this policy. The data protection regulations in Bangladesh or such other jurisdictions mentioned above may differ from those of your country of residence.</p>
                          <p>If you have any concerns in the processing your data and wish to withdraw your consent, you may do so by writing to the following email id: {{getCompanyInfo.website_content.contact_email}}.
                            However, if such processing of data is essential for us to be able to provide service to you, then we may not be able to serve or confirm your bookings after your withdrawal of consent.</p>
                          <p>A withdrawal of consent by you for us to process your information may:</p>
                          <ul>
                            <li>severely inhibit our ability to serve you properly and in such case, we may have to refuse the booking altogether, or</li>
                            <li>unreasonably restrict us to service your booking (if a booking is already made) which may further affect your trip or may compel us to cancel your booking.</li>
                          </ul>

                          <h3>TYPE OF INFORMATION WE COLLECT AND ITS LEGAL BASIS</h3>
                          <p>The information as detailed below is collected for us to be able to provide the services chosen by you and also to fulfill our legal obligations as well as our obligations towards third parties as per our User Agreement.</p>
                          <p><strong>"Personal Information"</strong>&nbsp;of User shall include the information shared by the User and collected by us for the following purposes:</p>
                          <p><strong>Registration on the Website:&nbsp;</strong>Information which you provide while subscribing to or registering on the Website,
                            including but not limited to information about your personal identity such as name, gender, marital status, religion, age etc., your contact details such as your email address, postal addresses, telephone (mobile).</p>

                          <h3>HOW WE USE YOUR PERSONAL INFORMATION:</h3>
                          <p>The Personal Information collected maybe used in the following manner:</p>
                          <p><strong>While making a booking</strong></p>
                          <p>While making a booking, we may use Personal Information including, payment details which include cardholder name, credit/debit card number (in encrypted form) with expiration date, banking details, wallet details etc.
                            as shared and allowed to be stored by you. We may also use the information of travelers list as available in or linked with your account. This information is presented to the User at the time of making a booking to enable you to complete your bookings expeditiously.</p>
                          <p><strong>We may also use your Personal Information for several reasons including but not limited to:</strong></p>
                          <ul>
                            <li>confirm your reservations with respective service providers;</li>
                            <li>keep you informed of the transaction status;</li>
                            <li>send booking confirmations via sms;</li>
                            <li>send any updates or changes to your booking(s);</li>
                            <li>allow our customer service to contact you, if necessary;</li>
                            <li>customize the content of our website, mobile site and mobile app;</li>
                            <li>request for reviews of products or services or any other improvements;</li>
                            <li>send verification message(s);</li>
                            <li>validate/authenticate your account and to prevent any misuse or abuse.</li>
                            <li>contact you on your birthday/anniversary to offer a special gift or offer.</li>
                          </ul>
                          <p><strong>Surveys:</strong></p>
                          <p>We value opinions and comments from our Users and frequently conduct surveys, both online and offline. Participation in these surveys is entirely optional.
                            Typically, the information received is aggregated, and used to make improvements to Website, other Sales Channels, services and to develop appealing content, features and promotions for members based on the results of the surveys. Identity of the survey participants is anonymous unless otherwise stated in the survey.</p>
                          <p><strong>Marketing Promotions, Research and Programs:</strong></p>
                          <p>Marketing promotions, research and programs help us to identify your preferences, develop programs and improve user experience. {{ getCompanyInfo.name }} frequently sponsors promotions to give its Users the opportunity to win great travel and travel related prizes.
                            Personal Information collected by us for such activities may include contact information and survey questions. We use such Personal Information to notify contest winners and survey information to develop promotions and product improvements.
                            As a registered User, you will also occasionally receive updates from us about fare sales in your area, special offers, new {{ getCompanyInfo.name }} services, other noteworthy items (like savings and benefits on airfares, hotel reservations, holiday packages, car rentals and other travel services) and marketing programs.</p>
                          <p>In addition, you may look forward to receiving periodic marketing emails, newsletters and exclusive promotions offering special deals.</p>
                          <p>From time to time we may add or enhance services available on the Website. To the extent these services are provided, and used by you, we will use the Personal Information you provide to facilitate the service(s) requested.
                            For example, if you email us with a question, we will use your email address, name, nature of the question, etc. to respond to your question. We may also store such Personal Information to assist us in making the Website the better and easier to use for our Users.</p>
                          <p>{{ getCompanyInfo.name }} may from time to time launch reward programs by way of which users may stand to win travel related rewards or other rewards. We may use your Personal Information to enroll you in the rewards program and status of the same will be visible each time you log in to the Website.
                            Depending on the reward program, each time you win a reward, {{ getCompanyInfo.name }} may share your Personal Information with a third party that will be responsible for fulfilling the reward to you. You may however choose to opt out of such reward programs by writing to us.
                            For various purposes such as fraud detection, offering bookings on credit etc., we at times may verify information of customers on selective basis, including their credit information.
                            Additionally, {{ getCompanyInfo.name }} may share your Personal Information, anonymized and/ or aggregated data to a third party that {{ getCompanyInfo.name }} may engage to perform certain tasks on its behalf,
                            including but not limited to payment processing, data hosting, data processing and assessing credit worthiness for offering bookings on credit.</p>

                          <h3>COOKIES AND SESSION DATA</h3>
                          <p><strong>Cookies:</strong></p>
                          <p>{{ getCompanyInfo.name }} uses cookies to personalize your experience on the Website and the advertisements that maybe displayed. {{ getCompanyInfo.name }}&rsquo;s use of cookies is similar to that of any other reputable online companies.</p>
                          <p>Cookies are small pieces of information that are stored by your browser on your device's hard drive. Cookies allow us to serve you better and more efficiently. Cookies also allow ease of access, by logging you in without having to type your login name each time (only your password is needed);
                            we may also use such cookies to display any advertisement(s) to you while you are on the Website or to send you offers (or similar emails &ndash; provided you have not opted out of receiving such emails) focusing on destinations which may be of your interest.</p>
                          <p>A cookie may also be placed by our advertising servers, or third party advertising companies. Such cookies are used for purposes of tracking the effectiveness of advertising served by us on any website,
                            and also to use aggregated statistics about your visits to the Website in order to provide advertisements in the Website or any other website about services that may be of potential interest to you.
                            The third party advertising companies or advertisement providers may also employ technology that is used to measure the effectiveness of the advertisements. All such information is anonymous.
                            This anonymous information is collected through the use of a pixel tag, which is an industry standard technology and is used by all major websites. They may use this anonymous information about your visits to the Website in order to provide advertisements about goods and services of potential interest to you.
                            No Personal Information is collected during this process. The information so collected during this process, is anonymous, and does not link online actions to a User</p>
                          <p>Most web browsers automatically accept cookies. Of course, by changing the options on your web browser or using certain software programs, you can control how and whether cookies will be accepted by your browser.</p>
                          <p><strong>Automatic Logging of Session Data:</strong></p>
                          <p>Each time you access the Website your session data gets logged. Session data may consist of various aspects like the IP address, operating system and type of browser software being used and the activities conducted by the User while on the Website.
                            We collect session data because it helps us analyze User&rsquo;s choices, browsing pattern including the frequency of visits and duration for which a User is logged on. It also helps us diagnose problems with our servers and lets us better administer our systems.
                            The aforesaid information cannot identify any User personally. However, it may be possible to determine a User's Internet Service Provider (ISP), and the approximate geographic location of User's point of connectivity through the above session data.</p>

                          <h3>HOW CAN YOU OPT-OUT OF RECEIVING OUR PROMOTIONAL E-MAILS?</h3>
                          <p>You will occasionally receive e-mail updates from us about fare sales in your area, special offers, new {{ getCompanyInfo.name }} services, and other noteworthy items. We hope you will find these updates interesting and informative.
                            If you wish not to receive them, please click on the "unsubscribe" link or follow the instructions in each e-mail message.</p>
                          <h3>PERMISSIONS REQUIRED FOR USING OUR MOBILE APPLICATIONS</h3>
                          <p>When the {{ getCompanyInfo.name }} app is installed on your phone or tablet, a list of permissions appear and are needed for the app to function effectively. There is no option to customize the list. The permissions that {{ getCompanyInfo.name }} requires and the data that shall be accessed and its use is as below:</p>
                          <p><strong>Android permissions:</strong></p>
                          <p><strong>Device &amp; App history:</strong>&nbsp;We need your device permission to get information about your device, like OS (operating system) name, OS version, mobile network, hardware model, unique device identifier, preferred language, etc.
                            Basis these inputs, we intend to optimize your travel booking experience, use OS specific capabilities to drive great in-funnel experiences using components of device&rsquo;s OS, etc.</p>
                          <p><strong>Identity:</strong>&nbsp;This permission enables us to know about details of your account(s) on your mobile device. We use this info to auto-fill your email ID&rsquo;s and provide a typing free in-funnel experience.
                            It helps us map email ID&rsquo;s to a particular user to give you the benefit of exclusive travel offers, wallet cash-backs, etc. It also allows facilitating your Facebook and Google+ login.</p>
                          <p><strong>SMS:</strong>&nbsp;If you allow us to access your SMS, we read your SMS to autofill or prepopulate &lsquo;OTP&rsquo; while making a transaction and to validate your mobile number.
                            This provides you a seamless purchase experience while making a booking and you don&rsquo;t need to move out of the app to read the SMS and then enter it in the app.</p>
                          <p><strong>Phone:</strong>&nbsp;The app requires access to make phone calls so that you can make phone calls to our customer contact centers directly through the app.</p>
                          <p><strong>Contacts:</strong>&nbsp;If you allow us to access your contacts, it enables us to provide a lot of social features with your friends, inviting your friends to try our app, send across referral links to your friends, etc.
                            We may also use this information to make recommendations for bus travel. This information will be stored on our servers and synced from your phone.</p>

                          <h3>HOW WE PROTECT YOUR PERSONAL INFORMATION?</h3>
                          <p>All payments on the Website are secured. This means all Personal Information you provide is transmitted using TLS (Transport Layer Security) encryption. TSL is a proven coding system that lets your browser automatically encrypt, or scramble, data before you send it to us.
                            Website has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server.
                            Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.</p>

                          <h3>YOUR RIGHTS QUA PERSONAL INFORMATION</h3>
                          <p>You may access your Personal Information from your user account with {{ getCompanyInfo.name }}. You may also correct your personal information or delete such information (except some mandatory fields) from your user account directly.
                            If you don&rsquo;t have such a user account, then you write to {{getCompanyInfo.website_content.contact_email}}</p>

                          <h3>CHANGES TO THE PRIVACY POLICY</h3>
                          <p>We reserve the rights to revise the Privacy Policy from time to time to suit various legal, business and customer requirement. We will duly notify the users as may be necessary.</p>
                          <p><strong>You may always submit concerns regarding this Privacy Policy via email to us at </strong><strong>{{getCompanyInfo.website_content.contact_email}} {{ getCompanyInfo.name }} shall endeavor to respond to all reasonable concerns and inquires.</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .spinner.spinner-right {
        padding-right: 3.5rem !important;
    }
    .bg-green{
        background-color: #20b887 !important;
        color:white;
    }
</style>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "privacy-policy",
        computed:{
          ...mapGetters(['getCompanyInfo'])
        },
    };
</script>
